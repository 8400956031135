import React from 'react';

import Layout from '../components/layout';

class MinaInstallningar extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "https://subscription-center.datatalks.se/init.min.js?client_id=8pqc2fll2hjmbv8w_Lantmannen";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return(
    <Layout articlelevel={100}>
      <>
        <div className="contentWrapper flex">
          <div className="content content--inverted">
            <div id="subscriptionCenter"></div>
          </div>
        </div>
      </>
    </Layout>
    );
  };
}

export default MinaInstallningar;